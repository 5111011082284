import { Box, Grid, Typography } from "@mui/material";

const StatControl = (props) => {
    const {
        rgbaOne,
        rgbaTwo,
        textHeader,
        colorOfSecondBlock,
        preStatDat,
        symbol,
        textFooter,
        CustomBox,
    } = props.prep
    return (
        <Grid item xl={4} lg={4} l={4} md={6} s={12} sm={12} xs={12} py={3}>
            <CustomBox elevation={6} sx={{ background: `linear-gradient(320deg, ${rgbaOne}, ${rgbaTwo} 100%)` }}>
                <Box sx={{ maxWidth: '350px', maxHeight: '238px', display: 'flex', justifyContent: 'center', mb: '10px' }} elevation={3}>
                    {/* <DateRange color='emerald' sx={{ mr: 1 }} /> */}
                    {props.children}
                    <Typography variant="h2">{textHeader}</Typography>
                </Box>
                <Typography sx={{ fontSize: '48px', fontWeight: 700 }} color={`${colorOfSecondBlock}`}>
                    {Math.round(preStatDat)}
                    <Typography component={'span'} variant="h3"> {symbol}</Typography>
                </Typography>
                <Typography variant="caption">{textFooter}</Typography>
            </CustomBox>
        </Grid>
    )
}

export default StatControl;