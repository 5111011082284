import { Box, Grid, Typography } from "@mui/material";
import { PrepForBlockResult } from "./PrepForBlockResult";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const BlockResult = ({ statDat, CustomBox }) => {
  const BlockResult = statDat.map((apart, index) => {
    const textPaycheck = apart.name.split(", ").pop();
    return (
      <div key={index}>
        <Typography textAlign="center" variant="h2" fontWeight="500" mt={4}>
          <Box display="inline-flex" alignItems="center">
            <InfoOutlinedIcon color="primary" sx={{ mr: 1 }} />
            Данные {apart.name.split("Доход").pop()}
          </Box>
        </Typography>

          <PrepForBlockResult
            apart={apart}
            textPaycheck={textPaycheck}
            CustomBox={CustomBox}
          />
      </div>
    );
  });
  return <>{BlockResult}</>;
};
