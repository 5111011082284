import { Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState, useEffect } from "react";
import Spinner from "../spinner/Spinner";
import BlockOne from "./BlockOne";

const MonthStat = (props) => {
  // console.log('render monthStat')
  // console.log("MonthStatProps", props);
  const { data } = props;
  // const [statDat, setStatDat] = useState([]);

  // useEffect(() => {
  //   setStatDat(data);
  // }, [data]);

  const CustomBox = styled(Paper)({
    maxWidth: "350px",
    minWidth: "250px",
    minHeight: "250px",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "35px 0 35px 60px",
    margin: "0 auto",
  });

  return (
    <>
      {/* {console.log("data", data)} */}
      {!data.length ? <Spinner /> : <BlockOne statDat={data} CustomBox={CustomBox} />}
    </>
  );
};

export default MonthStat;
