import { Box, Typography, Button, IconButton } from "@mui/material";
import { ArrowBack, InsertChartOutlined, Download, ContentCopy, CheckCircle, Close } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { getData } from "../services/services";
import TitleBar from "../titleBar/TitleBar";
import Spinner from '../spinner/Spinner';
import "./singleReport.css"
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import PDFViewer from "./ViewPDF";

const SingleReport = () => {
  // console.log('render SingleReport');

  const { apartmentId } = useParams();
  let { reportId } = useParams();

  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(true);

  const [newFormatReport, setNewFormatReport] = useState(false)

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    getData('viewReport', {
      user_id: parseInt(user.id),
      apartment_id: apartmentId,
      report_id: reportId,
    })
      .then(res => {
        setReport(res);
        if (res.DocumentGUID !== undefined) {
          setNewFormatReport(res.DocumentGUID === null ? false : true)
        }
        setLoading(false)
      })

  }, [])


  useEffect(() => {
    getData('readReport', {
      report_id: parseInt(reportId),
    })
  }, [])

  function createMarkupHeader() {
    const htmlHeader = report.html_header
    return { __html: htmlHeader };
  }
  function createMarkupBody() {
    const htmlBody = report.html
    return { __html: htmlBody };
  }

  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
    getData('doAccepted', {
      report_id: parseInt(reportId),
    })
    // console.log('handleClick')
  };

  const handlePDF = (canvas) => {
    const imgData = canvas.toDataURL('img/png');
    const doc = new jsPDF('p', 'mm', 'a4');
    const componentWidth = doc.internal.pageSize.getWidth() - 20;
    const componentHeight = doc.internal.pageSize.getHeight() - 150;
    // console.log(componentWidth, componentHeight);
    return doc.addImage(imgData, 'PNG', 10, 10, componentWidth, componentHeight);
  }

  const downloadPDF = () => {
    const capture = document.querySelector(".testPDF")
    html2canvas(capture).then((canvas) => {
      const doc = handlePDF(canvas)
      doc.save('Report.pdf');
    })
  }

  const printPDF = () => {
    const capture = document.querySelector(".testPDF")
    html2canvas(capture).then((canvas) => {
      const doc = handlePDF(canvas)
      doc.autoPrint();
      window.open(doc.output('bloburl'), '_blank');
    })
  }

  return (
    <>
      {loading ? <Spinner /> :
        (newFormatReport && <PDFViewer show={show} handleClick={handleClick} report={report} apartmentId={apartmentId} />) ||
        <div className="testPDF">
          <TitleBar
            arrow={
              <IconButton
                component={Link}
                to={`/apartments/${apartmentId}/reports`}
              >
                <ArrowBack />
              </IconButton>}
            icon={
              <InsertChartOutlined
                color="primary"
                fontSize="large"
                sx={{ m: '0 10px 0' }} />}
            title='Отчеты' />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: { xs: 'flex-start', md: 'flex-end' }, mb: '20px', flexDirection: { xs: 'column', md: 'row' } }}>

            <div dangerouslySetInnerHTML={createMarkupHeader()} />

            <Box data-html2canvas-ignore="true" sx={{ display: 'flex' }}>
              <Button
                variant='contained'
                onClick={() => {
                  handleClick()
                }}
              >Принять</Button>
              {show ? (
                <Button
                  sx={{ position: 'absolute', top: '30%', left: '40%', width: '250px', mr: 1, mb: 5, textTransform: 'none', bgcolor: 'rgba(226, 236, 245, 1)' }}
                  startIcon={<CheckCircle />}
                  endIcon={<Close />}
                  onClick={handleClick}
                >
                  <Typography >Отчет принят</Typography>
                </Button>
              ) : null}
              <Button onClick={downloadPDF} variant='outlined' sx={{ mr: 1, ml: 1 }}>
                <Download />
                Скачать
              </Button>
              <Button onClick={printPDF} variant='outlined'>
                <ContentCopy />
                Печать
              </Button>
            </Box>
          </Box>
          <div dangerouslySetInnerHTML={createMarkupBody()} />
        </div>
      }
    </>
  )

}

export default SingleReport;


