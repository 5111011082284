import { Analytics } from '@mui/icons-material';
import StatControl from "../../HOC/StatControl";

export const AverageTarif = (props) => {
    const prep = {
        rgbaOne: `rgba(103, 110, 188, 0.58)`,
        rgbaTwo: `rgba(103, 110, 188, 0.1)`,
        textHeader: "Средний тариф",
        colorOfSecondBlock: "purple.main",
        symbol: "руб",
        textFooter: "Cтоимость 1 суток аренды",
        preStatDat: props.averageTarif,
        ...props
    }
    return (
        <StatControl prep={prep}>
            <Analytics color='purple' sx={{ mr: 1 }} />
        </StatControl>
    )
}

