import { Grid } from "@mui/material";
import { OccupancyRate } from "../averageRate/OccupancyRate";
import { AverageTarif } from "../loadLevel/AverageTarif";
import { PaycheckComplete } from "../paycheck/PaycheckComplete";

export const PrepForBlockResult = ({ apart, textPaycheck, CustomBox }) => {
  const PrepForBlockResult = apart.data.map((block, index) => {
    return (
      <Grid key={index} container spacing="18px" justifyContent="space-between" mt={2}>
        <OccupancyRate
          occupancy={block.occupancy}
          CustomBox={CustomBox}
        />
        <AverageTarif averageTarif={block.averageTarif} CustomBox={CustomBox} />
        <PaycheckComplete
          text={textPaycheck}
          income={block.income}
          CustomBox={CustomBox}
        />
      </Grid>
    );
  });
  return <>{PrepForBlockResult}</>;
};
