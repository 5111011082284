import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useMediaQuery, useTheme } from "@mui/material";

export default function SelectLabels(props) {
  const handleChange = (event) => {
    props.setAge(event.target.value);
  };
  // console.log("SelectLabels PROPS", props.statDat);
  
  const theme = useTheme();
  const isSmallScreen = useMediaQuery('(max-width:856px)');
  const MenuItems = props.statDat.map((item, index) => {
    return (
      <MenuItem key={index} value={index}>
        {/* {apart.name.split("Доход").pop()} */}
        Данные {item.name.split("Доход").pop()}
        {/* {item.date} */}
      </MenuItem>
    );
  });
  return (
    <div>
      <FormControl sx={{ mt: 3, mb: isSmallScreen ? 13 : 0, width: "265px" }}>
        <Select
          value={props.age}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          {MenuItems}
        </Select>
      </FormControl>
    </div>
  );
}
