import {
  Box,
  Typography,
  Button,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  SxProps,
} from "@mui/material";
import { ArrowBack, CottageOutlined, QueryStats } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";

import { useUser } from "../userContext/UserContext";
import { useAparts } from "../apartsContext/ApartsContext";
import { getData } from "../services/services";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ruLocale from "date-fns/locale/ru";

import TitleBar from "../titleBar/TitleBar";
import Subtitle from "../subtitle/Subtitle";
import MonthStat from "../monthStat/MonthStat";
import QuartYearStat from "../quartYearStat/QuartYearStat";
import Spinner from "../spinner/Spinner";

const SingleApartment = () => {
  // console.log('render SingleApartment')

  const { apartmentId } = useParams();
  const { userName, getUserName } = useUser();
  const { apartList, getApartList } = useAparts();

  //функция для получения предыдущего месяца для статистики за месяц
  const getMonthBefore = () => {
    let somedate = new Date();
    somedate.setMonth(somedate.getMonth() - 1);
    somedate.setDate(1);
    return somedate;
  };

  const [date, setDate] = useState(getMonthBefore());
  const [statMonth, setStatMonth] = useState("month");
  const [statistics, setStatistics] = useState([]);
  const [response, setResponse] = useState({ data: [], isLoading: false });
  const [statisticsBlocks, newStatisticsBlocks] = useState([]);
  // console.log("данные", response);
  useEffect(() => {
    // console.log("useEffectOnmountCoomponent");
    getApartList();
    getUserName();
  }, []);

  useEffect(() => {
    // console.log("OnrequesISWORK");
    if (statMonth == "month") {
      setResponse({ ...response, isLoading: true });
      onRequestNewStat(getCurrentDate(date));
      setResponse(() => ({ ...response, isLoading: false }));
    } else if (statMonth == "quartal") {
      // onRequest(getQuartal(date));
      setResponse({ ...response, isLoading: true });
      onRequestNewStat(getQuartal(date));
      setResponse(() => ({ ...response, isLoading: false }));
    } else if (statMonth == "year") {
      // onRequest(getFullYear(date));
      setResponse({ ...response, isLoading: true });
      onRequestNewStat(getFullYear(date));
      setResponse(() => ({ ...response, isLoading: false }));
    }
  }, [statMonth, date, apartList]);

  const onRequest = (date) => {
    getData("getApartStatistic", {
      apartment_id: apartmentId, // ПОМЕНЯТЬ НА apartmentId, 357
      date: date,
    }).then((res) => {
      setStatistics(res.response);
    });
  };

  const onRequestNewStat = (date) => {
    debugger;
    if (!apartList) {
      debugger;
      return;
    }

    const apartFindPredicate = ({ id }) => {
      return id === apartmentId;
    };

    const contractId = apartList.find(apartFindPredicate).contract_id;

    getData("getApartStatisticExt", {
      apartment_id: apartmentId, // apartmentId 357
      date: date, // ["5.2022"]
      contract_id: contractId, // "123"
    }).then((res) => {
      // console.log("RESPONSE:", res);
      // console.log("responseINSIDEINSDE", res.response.responce)
      setResponse(() => ({ ...response, data: res.response.responce }));
    });
  };

  //получение текущей/выбранной даты
  const getCurrentDate = (value) => {
    let currentDate = [value.getMonth() + 1 + "." + value.getFullYear()];
    return currentDate;
  };

  // Функция получения массива месяцев квартала
  const getQuartal = (value) => {
    let arr_quartals = [
      {
        1: [1, 2, 3],
      },
      {
        2: [4, 5, 6],
      },
      {
        3: [7, 8, 9],
      },
      {
        4: [10, 11, 12],
      },
    ];
    let selectedQuartalYear = value.getFullYear();
    let prevmonth = value.getMonth() + 1;

    let NumberQuartal = Math.ceil(prevmonth / 3);
    let arrQuartal = arr_quartals[NumberQuartal - 1][NumberQuartal];
    return arrQuartal.map((item) => item + "." + selectedQuartalYear);
    //return (isCurQuartalClosed ? curentQuartal : _prevQuartal).map(item => (item > 0) ? item + '.' + currentYear : (item + 12) + '.' + (currentYear - 1));
  };

  // Функция получения массива месяцев года
  const getFullYear = (value) => {
    let currentYear = value.getFullYear();
    const yearArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
      (item) => item + "." + currentYear
    );
    return yearArr;
  };

  const handleStatMonth = (event, newStatMonth) => {
    setStatMonth(newStatMonth);
  };

  function renderApartInfo(arr) {
    const info = arr.map((item) => {
      // console.log(item);
      if (item.id == apartmentId) {
        return (
          <Box key={item.id}>
            <TitleBar
              arrow={
                <IconButton component={Link} to="/apartments">
                  <ArrowBack />
                </IconButton>
              }
              icon={
                <CottageOutlined
                  color="primary"
                  fontSize="large"
                  sx={{ m: "0 10px 0" }}
                />
              }
              title={item.name}
            />
            <Subtitle title="Адрес:" text={item.address} />
            <Subtitle title="Договор:" text={"№ " + item.contract_num} />
            <Subtitle
              title="Владелец: "
              text={
                userName +
                ", доля владения - " +
                parseInt(item.interest) * 100 +
                "%"
              }
            />
            <Subtitle
              title="Площадь: "
              text={item.totalArea != null ? item.totalArea + " кв.м." : "-"}
            />
          </Box>
        );
      }
    });
    return <>{info}</>;
  }
  const content = apartList ? renderApartInfo(apartList) : null;
  const monthStat =
    statMonth == "month" && response.data ? <MonthStat data={response.data} /> : null;
  // const monthStat = statMonth == "month" && statisticsBlocks ? <MonthStat data={statisticsBlocks}/> : null ;
  // const quartStat =
  //   statMonth == "quartal" && statisticsBlocks ? (
  //     <QuartYearStat data={statisticsBlocks} />
  //   ) : null;
  const quartStat =
    statMonth == "quartal" && response.data ? (<QuartYearStat data={response.data} />) : null;

  const yearStat =
    statMonth == "year" && response.data ? (
      <QuartYearStat data={response.data} />
    ) : null;

  const CustomToggleBtn = styled(ToggleButton)({
    width: 85,
    height: 36,
    textTransform: "none",
    fontSize: 16,
    fontWeight: 400,
    "&:hover": {
      backgroundColor: "rgba(9, 109, 217, 1)",
      color: "white",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: "rgba(9, 109, 217, 1)",
    },
  });
  const popperSx: SxProps = {
    "& .MuiCalendarPicker-root": {
      backgroundColor: "#fff",
    },
  };

  return (
    <>
      {content}
      <Box
        sx={{ display: "flex", alignItems: "flex-end", p: "10px 0px", mt: 2 }}
      >
        <Box width="60px"></Box>
        <QueryStats color="primary" fontSize="large" sx={{ mr: 2 }} />
        <Typography variant="h1">
          Статистика по загрузке, среднему тарифу и доходам за период
        </Typography>
      </Box>
      <Box
        maxWidth="550px"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", s: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
        }}
      >
        <ToggleButtonGroup
          color="primary"
          value={statMonth}
          exclusive
          onChange={handleStatMonth}
        >
          <CustomToggleBtn value="month">Месяц</CustomToggleBtn>
          <CustomToggleBtn value="quartal">Квартал</CustomToggleBtn>
          <CustomToggleBtn value="year">Год</CustomToggleBtn>
        </ToggleButtonGroup>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
          <DesktopDatePicker
            views={["month", "year"]}
            value={date}
            disableFuture={true}
            onChange={(newValue) => {
              setDate(newValue);
            }}
            PopperProps={{ sx: popperSx }}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& .MuiOutlinedInput-input ": { padding: 1, border: "none" },
                }}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      {monthStat}
      {quartStat}
      {yearStat}
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "space-around", l: "center" },
          mt: 2,
        }}
      >
        <Button
          sx={{ padding: "8px 96px", marginBottom: "10px" }}
          variant="contained"
          component={Link}
          to={`/apartments/${apartmentId}/reports`}
        >
          Отчеты
        </Button>
      </Box>
    </>
  );
};

export default SingleApartment;
